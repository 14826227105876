// for everythign outside ng-view
angular.module('sq.appController', ['sq.serverEvents.directive'])
.controller( 'AppController', function AppCtrl($scope, $rootScope)  {
  var vm = this;

  // $scope.$on('site:initialized', function() {
  //   vm.loginInit = false;
  // });

});
